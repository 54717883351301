import styled from '@emotion/styled'
import { motion } from "framer-motion";

export const SingleButtonContainer = styled(motion.div)`
  background: ${props => `url(${props.bg})`};
  width: ${props => props.width};
  height: ${props => props.height};
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  animation: btn ${props => props.animate ? 2 : 0}s infinite;
  animation-timing-function: ease-in-out;
  @keyframes btn {
    0% {
      /* opacity: 0; */
      transform: scale(1);
    }
    20% {
      /* opacity: 0.8; */
      transform: scale(1.2);
    }
    40% {
      /* opacity: 0.8; */
      transform: scale(1);
    }
    80% {
      /* opacity: 1; */
      transform: scale(1.2);
    }
    100% {
      /* opacity: 0; */
      transform: scale(1);
    }
  }
`;